import { Picker } from '@react-native-picker/picker';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Fragment, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text } from 'react-native';

import Button from '../components/Button';
import { getWebShopEndpoint } from '../utils/endpoint';
import { expoConfig, extra } from '../utils/extra';
import { productionOrganizations, stagingOrganizations } from '../utils/organization';

function useQuery() {
  if (typeof window === 'undefined') {
    return new URLSearchParams();
  }

  return new URLSearchParams(window.location.search);
}

export default function Web() {
  const query = useQuery();
  // クエリパラメータを取得する
  const isTerminal = query.get('terminal') === 'true';

  const [selectOrganization, setSelectOrganization] = useState('none');

  const onPress = async () => {
    if ('ReactNativeWebView' in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: 'organization',
          data: {
            organization: selectOrganization,
          },
        })
      );
    }
    await Linking.openURL(getWebShopEndpoint(selectOrganization));
  };

  // Web版の本番・ステージング判定用
  const isStaging = () => {
    const path = window.location.href;
    if (path.includes('localhost')) return true;
    // return extra?.stagingHomeUrl === path;
    return path.includes('https://businesses-staging.premium-control.jp/');
  };

  const handleHelpPage = async () => {
    // 楽天ペイターミナルの場合は、アプリ内ブラウザを開く
    if (isTerminal) {
      await Linking.openURL(extra?.helpPageUrl);
      return;
    }
    WebBrowser.openBrowserAsync(extra?.helpPageUrl);
  };

  const organizations = isStaging() ? stagingOrganizations : productionOrganizations;

  return (
    <SafeAreaView style={styles.safeArea}>
      <LinearGradient colors={['#4FC3CF', '#44A8B3']} style={styles.container}>
        <View style={styles.form}>
          <Text style={{ fontSize: 16 }}>まちのわ</Text>
          <Text style={{ fontSize: 28 }}>店舗用アプリ</Text>
          <View style={styles.organizationLabel}>
            <View style={styles.organizationLabelBorder} />
            <Text style={{ marginHorizontal: 8, fontSize: 16 }}>地域選択</Text>
            <View style={styles.organizationLabelBorder} />
          </View>
          <Picker
            style={pickerStyle.select}
            itemStyle={{ fontSize: 16 }}
            selectedValue={selectOrganization as any}
            onValueChange={(itemValue, itemIndex) => setSelectOrganization(itemValue)}>
            <Picker.Item label="地域を選択" value="none" enabled={false} color="#000000" />
            {organizations.map((org) => (
              <Fragment key={org.value}>
                <Picker.Item label={org.name} value={org.value as any} />
              </Fragment>
            ))}
          </Picker>
          <View style={{ width: '100%', height: 36, marginBottom: 8 }}>
            <Button
              type="solid"
              color="#1C8F9C"
              name="ログイン画面へ →"
              disabled={selectOrganization === 'none'}
              onPress={onPress}
            />
          </View>
        </View>
        <Text style={styles.versionText}>
          バージョン {expoConfig?.version} {isStaging() && 'ステージング'}
        </Text>
        <View style={styles.helpPage}>
          <Text style={styles.helpPageText} onPress={handleHelpPage}>
            よくあるご質問
          </Text>
        </View>
      </LinearGradient>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    width: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 365,
    paddingHorizontal: 32,
    paddingVertical: 40,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 8,
  },
  organizationLabel: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 32,
  },
  organizationLabelBorder: {
    height: 1,
    flex: 0.5,
    borderBottomWidth: 1,
    borderColor: '#0BA7E5',
  },
  versionText: {
    marginTop: 8,
    color: '#FFFFFF',
  },
  helpPage: {
    width: '100%',
    alignItems: 'flex-end',
    paddingHorizontal: '3%',
    marginTop: 40,
  },
  helpPageText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
});

const pickerStyle = StyleSheet.create({
  select: {
    width: '100%',
    height: 36,
    marginVertical: 16,
    fontSize: 16,
    borderRadius: 4,
    borderColor: '#CED4DA',
    color: '#6C757D',
    backgroundColor: '#FFFFFF',
  },
});
