import React, { useContext, useEffect, useState } from 'react';

import Loading from '../components/Loading';
import UpdateModal from '../components/UpdateModal';
import WebViewScreen from '../components/WebViewScreen';
import { StateContext } from '../utils/StateProvider';
import { extra } from '../utils/extra';

let Updates;
try {
  if (!extra?.isTerminalBuild) {
    Updates = require('expo-updates');
  }
} catch {
  console.error('expo-updates is not installed');
}

export default function NativeApp() {
  const { loading, setLoading } = useContext(StateContext);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const checkOTAUpdate = async () => {
    try {
      if (!extra?.isTerminalBuild) {
        // 新バージョンチェック
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          setLoading(true);
          await Updates.fetchUpdateAsync();
          setLoading(false);
          setIsUpdateModal(true);
        }
      }
    } catch {}
  };
  const init = async () => {
    if (!extra?.isTerminalBuild) {
      try {
        await checkOTAUpdate();
      } catch {}
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <WebViewScreen />
      <Loading isVisible={loading} />
      {!extra?.isTerminalBuild && (
        <UpdateModal isVisible={isUpdateModal} update={() => Updates.reloadAsync()} />
      )}
    </>
  );
}
