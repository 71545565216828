import { Subscription } from 'expo-modules-core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, Alert, SafeAreaView } from 'react-native';
import WebView, { WebViewNavigation } from 'react-native-webview';
import {
  WebViewErrorEvent,
  WebViewHttpErrorEvent,
  WebViewMessageEvent,
} from 'react-native-webview/lib/WebViewTypes';

import Loading from './Loading';
import LoginModal from './LoginModal';
import NetworkError from './NetworkError';
import QRCodeReader from './QRCodeReader';
import WebViewNavBar from './WebViewNavBar';
import { QRCODE_READ_INTERVAL } from '../const/qrcode-read-interval';
import {
  LOGIN_SCREEN_ROUTE,
  NOTIFICATIONS_SCREEN_ROUTE,
  PAYMENT_HISTORIES_SCREEN_ROUTE,
  QRCODE_PAYMENT_SCREEN_ROUTE,
  QRCODE_READ_SCREEN_ROUTE,
} from '../const/route';
import { WebViewEvent } from '../const/webview-event';
import { StateContext } from '../utils/StateProvider';
import { extra } from '../utils/extra';
import {
  getLoginDataFromStorage,
  getLoginFormInputCode,
  LoginData,
  removeLoginDataFromStorage,
  setLoginDataToStorage,
} from '../utils/login';
import { isNetworkErrorResponse } from '../utils/network';
import { postDeviceTokenScript } from '../utils/scripts/device';
import { getInjectScript } from '../utils/scripts/intex';
import {
  ANDROID_INJECT_SCRIPT,
  HOME_ENDPOINT,
  IOS_INJECT_SCRIPT,
  PASSWORD_CHANGE_SUBMIT_CODE,
  QRCODE_READ_SCREEN_SCRIPT,
  SHOW_CAMERA_BUTTON_SCRIPT,
  SUBMIT_CODE,
} from '../utils/scripts/script';
import { setDeviceVolume } from '../utils/volume';

const CPM_PAYMENT_SIGNATURE = 'cpm_payment';

// NOTE: 楽天ペイターミナル端末ビルド時には以下のライブラリを読み込まない（ダミーのファイルを読み込む）
let addNotificationReceivedListener: (
  listener: (event: Notification) => void
) => Subscription | undefined;
let addNotificationResponseReceivedListener: (
  listener: (event: Notification) => void
) => Subscription | undefined;
let setNotificationHandler: (
  handler: {
    handleNotification: (notification: Notification) => Promise<any>;
    handleSuccess?: (notificationId: string) => void;
    handleError?: (notificationId: string, error: any) => void;
  } | null
) => void;
let registerForPushNotificationsAsync: () => Promise<string> | undefined;
try {
  if (!extra?.isTerminalBuild) {
    const utilNotification = require('../utils/notification');
    registerForPushNotificationsAsync = utilNotification.registerForPushNotificationsAsync;
    const expoNotifications = require('expo-notifications');
    addNotificationReceivedListener = expoNotifications.addNotificationReceivedListener;
    addNotificationResponseReceivedListener =
      expoNotifications.addNotificationResponseReceivedListener;
    setNotificationHandler = expoNotifications.setNotificationHandler;
  }
} catch {
  console.error('ライブラリの読み込みに失敗しました。');
}

const TITLE = 'QRコード読み取り';
const INSTRUCTION = 'QRコードを読み取ってください。';

function WebViewScreen() {
  const { setLoading, setIsConnect } = useContext(StateContext);
  const [isLogin, setIsLogin] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [scanned, setScanned] = useState(false);
  const webViewRef = useRef<WebView>(null);
  const [webViewState, setWebViewState] = useState<WebViewNavigation | null>(null);
  const [userData, setUserData] = useState<{
    data: LoginData;
    organization: string;
  }>({
    data: {
      email: '',
      password: '',
    },
    organization: '',
  });

  const onMessage = async (event: WebViewMessageEvent) => {
    const nativeMessage = JSON.parse(event.nativeEvent.data);
    switch (nativeMessage.event) {
      case WebViewEvent.LOGIN:
        // ログイン処理
        setUserData({
          ...userData,
          data: { email: nativeMessage.data.email, password: nativeMessage.data.password },
        });
        // eslint-disable-next-line no-case-declarations
        const loginData = await getLoginDataFromStorage(userData.organization);
        // 保存済みEmail/Passwordが一致してる場合はそのままログイン
        if (
          loginData &&
          loginData.email === nativeMessage.data.email &&
          loginData.password === nativeMessage.data.password
        ) {
          webViewRef.current!.injectJavaScript(SUBMIT_CODE);
        } else {
          // 不一致の場合、保存モーダルを表示
          setIsLogin(true);
        }
        return;
      case WebViewEvent.ORGANIZATION:
        // 地域選択処理
        setUserData({ ...userData, organization: nativeMessage.data.organization });
        return;
      case WebViewEvent.PASSWORD_CHANGE:
        // 変更パスワード保存処理
        await setLoginDataToStorage({
          data: {
            email: userData.data.email,
            password: nativeMessage.data.password,
          },
          organization: userData.organization,
        });
        webViewRef.current!.injectJavaScript(PASSWORD_CHANGE_SUBMIT_CODE);
        return;
      case WebViewEvent.OPEN_QRCODE_READER:
        // QRコード読み取り処理
        setLoading(true);
        setTimeout(() => {
          setScanned(false);
          setOpenCamera(true);
          setLoading(false);
        }, QRCODE_READ_INTERVAL);
    }
  };

  const onNavigationStateChange = async (state: WebViewNavigation) => {
    setWebViewState(state);
    if (!state.loading) {
      // ページごとの挿入スクリプトを実行
      const script = getInjectScript(state.url);
      if (script) webViewRef.current!.injectJavaScript(script);
      // ID/PASS自動入力
      if (state.url.includes(LOGIN_SCREEN_ROUTE)) {
        const loginData = await getLoginDataFromStorage(userData.organization);
        if (loginData) webViewRef.current!.injectJavaScript(getLoginFormInputCode(loginData));
      } else if (state.url.includes(PAYMENT_HISTORIES_SCREEN_ROUTE) && !extra?.isTerminalBuild) {
        // Push Device Tokenの送信
        const token = await registerForPushNotificationsAsync();
        if (token && typeof token === 'string') {
          const osType = Platform.OS === 'android' ? 10 : 20;
          webViewRef.current!.injectJavaScript(postDeviceTokenScript(token, osType));
        }
      } else if (state.url.includes(QRCODE_READ_SCREEN_ROUTE)) {
        webViewRef.current!.injectJavaScript(`${QRCODE_READ_SCREEN_SCRIPT}`);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, QRCODE_READ_INTERVAL);
        webViewRef.current!.injectJavaScript(`${SHOW_CAMERA_BUTTON_SCRIPT()}`);
      } else if (state.url.includes(QRCODE_PAYMENT_SCREEN_ROUTE)) {
        // 決済音量の調整
        await setDeviceVolume();
      }
    }
  };

  const onError = (error: WebViewErrorEvent) => {
    // ネットワークエラー
    const isNetworkError = isNetworkErrorResponse(error.nativeEvent.code);
    if (isNetworkError) {
      setIsConnect(false);
      Alert.alert(
        'インターネットに接続できません',
        '通信環境や端末の機内モード設定をご確認の上、再度お試しください。',
        [
          {
            text: '再接続',
            onPress: () => {
              webViewRef.current!.reload();
              setIsConnect(true);
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  // WebViewのHTTPエラー
  const onHttpError = (error: WebViewHttpErrorEvent) => {
    // HTTPエラーの情報を取得
    const statusCode = error.nativeEvent.statusCode;
    if (statusCode === 403) {
      if (
        error.nativeEvent.url.includes(
          'https://shop.furusato-staging.premium-control.jp/shop/qrCodeCard/balance'
        )
      ) {
        Alert.alert(
          'QRコード読み取りエラー',
          'QRコードのデータが無効です。再度読み取ってください。'
        );
        webViewRef.current!.injectJavaScript(
          'window.location.href = "https://shop.furusato-staging.premium-control.jp/shop/qrCodeCard/scan"'
        );
        return;
      }
      // 403エラーの場合は地域選択画面に戻す
      webViewRef.current!.injectJavaScript(`window.location.href = '${HOME_ENDPOINT}'`);
    }
  };

  const onPressLogin = async (rememberMe: boolean = false) => {
    setIsLogin(false);
    setLoading(true);
    if (rememberMe) {
      // パスワード保存処理
      await setLoginDataToStorage({ data: userData.data, organization: userData.organization });
    } else {
      // パスワード削除処理
      if (userData.organization) await removeLoginDataFromStorage(userData.organization);
    }
    webViewRef.current!.injectJavaScript(SUBMIT_CODE);
    setLoading(false);
  };

  const handleBarCodeScanned = (scanData) => {
    setScanned(true);
    let scanDataJson;
    try {
      scanDataJson = scanData?.data ? JSON.parse(scanData.data) : null;
    } catch (error) {
      console.error(error);
      Alert.alert(
        'QRコードの読み取りエラー',
        'QRコードのデータが無効です。再度読み取ってください。'
      );
      setOpenCamera(false);
      return;
    }
    // 読み込んだQRの内容によって、CPM決済かQRコードチャージであるか判定し処理を分ける
    if (scanDataJson?.signature === CPM_PAYMENT_SIGNATURE) {
      const jsCode = `document.getElementById('cpm-input-json').value = '${scanData.data}';
        document.forms['cpmPayment'].submit();`;
      webViewRef.current!.injectJavaScript(jsCode);
    } else {
      const jsCode = `document.getElementById('input-json').value = '${scanData.data}';
        document.forms['getBalance'].submit();`;
      webViewRef.current!.injectJavaScript(jsCode);
    }

    setOpenCamera(false);
  };

  const handleCloseBarcodeReader = () => {
    setOpenCamera(false);
  };

  const isVisibleNavBar = () => {
    // @topic 外部サイトとヘルプページの場合にアクセスした場合にNavBarを表示
    return (
      !webViewState?.url.includes('premium-control.jp') ||
      webViewState?.url.includes('hosting.businesses.premium-control.jp')
    );
  };

  const navBarGoback = () => {
    // ヘルプページの場合は地域選択画面に強制的に戻す
    if (webViewState?.url.includes('hosting.businesses.premium-control.jp')) {
      webViewRef.current!.injectJavaScript(`window.location.href = '${HOME_ENDPOINT}'`);
    } else {
      // その他は通常通り戻す
      webViewRef.current?.goBack();
    }
  };

  const backgroundNotificationEvent = () => {
    // iOSでは一度リロードを挟む
    if (Platform.OS === 'ios') {
      webViewRef.current!.reload();
    }

    webViewRef.current!.injectJavaScript(`window.location.href = '${NOTIFICATIONS_SCREEN_ROUTE}';`);
    setOpenCamera(false); // @topic Push通知をタップした際にはカメラ画面を強制的に閉じる
  };

  const foregroundNotificationEvent = () => {
    // @todo フォアグランドでのPush通知受信時のイベントがあれば今後記述する
  };

  useEffect(() => {
    if (!extra?.isTerminalBuild) {
      // フォアグラウンド時の通知設定
      setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: true,
        }),
      });
      // プッシュ通知を受信したときのイベントハンドラを登録
      const notificationListener = addNotificationReceivedListener(foregroundNotificationEvent);

      // 通知をタップした時のイベントハンドラを登録
      const foregroundNotificationListener = addNotificationResponseReceivedListener(
        backgroundNotificationEvent
      );

      return () => {
        if (notificationListener !== undefined) notificationListener.remove();
        if (foregroundNotificationListener !== undefined) foregroundNotificationListener.remove();
      };
    }
  }, []);

  return (
    <>
      <SafeAreaView style={styles.safeArea}>
        <NetworkError />
        <WebViewNavBar
          visible={isVisibleNavBar()}
          goBack={navBarGoback}
          goForward={() => webViewRef.current?.goForward()}
          webViewState={webViewState}
        />
        <WebView
          ref={webViewRef}
          style={styles.webview}
          source={{
            uri: HOME_ENDPOINT,
          }}
          geolocationEnabled
          setBuiltInZoomControls
          setDisplayZoomControls
          originWhitelist={['*']}
          javaScriptEnabled
          injectedJavaScript={Platform.OS === 'android' ? ANDROID_INJECT_SCRIPT : IOS_INJECT_SCRIPT}
          hideKeyboardAccessoryView
          allowsInlineMediaPlayback
          mediaPlaybackRequiresUserAction={false}
          mediaCapturePermissionGrantType="grantIfSameHostElsePrompt"
          incognito
          cacheEnabled={false}
          onLoadStart={() => setLoading(true)}
          onLoad={() => setLoading(false)}
          onError={onError}
          onHttpError={onHttpError}
          renderLoading={() => {
            return <Loading isVisible />;
          }}
          onMessage={onMessage}
          onNavigationStateChange={onNavigationStateChange}
          userAgent={
            Platform.OS === 'ios'
              ? 'iPhone'
              : extra?.isTerminalBuild
                ? 'Android rakutenPayTerminal'
                : 'Android'
          }
          onContentProcessDidTerminate={() => webViewRef.current?.reload()}
          onRenderProcessGone={() => webViewRef.current?.reload()}
        />
        <LoginModal
          isVisible={isLogin}
          onBackdropPressed={() => setIsLogin(false)}
          login={onPressLogin}
        />
      </SafeAreaView>
      {openCamera && (
        <QRCodeReader
          title={TITLE}
          onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
          onClose={handleCloseBarcodeReader}
          instruction={INSTRUCTION}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  webview: {
    flex: 1,
  },
  buttonWrapper: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 5,
  },
});

export default WebViewScreen;
