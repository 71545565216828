const LOGIN_SCREEN_ROUTE = '/shop/login';
const PASSWORD_RESET_SCREEN_ROUTE = '/shop/password/reset';
const PASSWORD_CHANGE_SCREEN_ROUTE = '/shop/changePassword';
const PAYMENT_HISTORIES_SCREEN_ROUTE = '/shop/paymentHistories';
const NOTIFICATIONS_SCREEN_ROUTE = '/shop/backendNotifications';
const QRCODE_READ_SCREEN_ROUTE = '/shop/qrCodeCard/scan';
const QRCODE_PAYMENT_SCREEN_ROUTE = '/shop/qrCodeCard/pay';

export {
  LOGIN_SCREEN_ROUTE,
  PASSWORD_RESET_SCREEN_ROUTE,
  PASSWORD_CHANGE_SCREEN_ROUTE,
  PAYMENT_HISTORIES_SCREEN_ROUTE,
  NOTIFICATIONS_SCREEN_ROUTE,
  QRCODE_READ_SCREEN_ROUTE,
  QRCODE_PAYMENT_SCREEN_ROUTE,
};
