import { VolumeManagerSetVolumeConfig, VolumeResult } from 'react-native-volume-manager';

const VOLUME_CHANGE_TIME = 3000;

let VolumeManager:
  | {
      getVolume: () => Promise<VolumeResult>;
      setVolume: (value: number, config?: VolumeManagerSetVolumeConfig) => Promise<void>;
    }
  | undefined;

try {
  VolumeManager = require('react-native-volume-manager').VolumeManager;
} catch {
  console.log('react-native-volume-manager is not available.');
}

const setDeviceVolume = async () => {
  try {
    const result = await VolumeManager?.getVolume();
    const currentVolume = result?.volume;
    if (currentVolume === undefined) return;
    await VolumeManager?.setVolume(0.6);
    await new Promise((resolve) => setTimeout(resolve, VOLUME_CHANGE_TIME));
    await VolumeManager?.setVolume(currentVolume);
  } catch (error) {
    console.log(error);
  }
};

export { setDeviceVolume };
