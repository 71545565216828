import {
  HELPPAGE_SCRIPT,
  PASSWORD_CHANGE_SCREEN_SCRIPT,
  PASSWORD_RESET_SCREEN_SCRIPT,
  SHOP_LOGIN_SCREEN_SCRIPT,
} from './script';
import {
  LOGIN_SCREEN_ROUTE,
  PASSWORD_CHANGE_SCREEN_ROUTE,
  PASSWORD_RESET_SCREEN_ROUTE,
} from '../../const/route';
import { extra } from '../extra';

const getInjectScript = (path: string) => {
  if (path.includes(LOGIN_SCREEN_ROUTE)) {
    return SHOP_LOGIN_SCREEN_SCRIPT();
  } else if (path.includes(PASSWORD_RESET_SCREEN_ROUTE)) {
    return PASSWORD_RESET_SCREEN_SCRIPT;
  } else if (path.includes(PASSWORD_CHANGE_SCREEN_ROUTE)) {
    return PASSWORD_CHANGE_SCREEN_SCRIPT;
  } else if (path.includes(extra?.helpPageUrl)) {
    // 地域選択画面が存在しない自治体ではスクリプト不要
    return extra?.isHelpPageBackButton ? HELPPAGE_SCRIPT : '';
  }

  return null;
};

export { getInjectScript };
