import * as SecureStore from 'expo-secure-store';

import { extra } from './extra';

type Callback = (error?: Error | null) => void;

// @react-native-async-storage/async-storage libs
let getItemAsync: (key: string, callback?: any) => Promise<string | null>;
let setItemAsync: (key: string, value: string, callback?: Callback) => Promise<void>;
let removeItemAsync: (key: string, callback?: Callback) => Promise<void>;

try {
  if (!extra?.isTerminalBuild) {
    const reactNativeAsyncStorage = require('@react-native-async-storage/async-storage').default;
    getItemAsync = reactNativeAsyncStorage.getItem;
    setItemAsync = reactNativeAsyncStorage.setItem;
    removeItemAsync = reactNativeAsyncStorage.removeItem;
  }
} catch {
  console.error('AsyncStorage is not available');
}

const getRememberLoginDataKey = (organization: string) => {
  return `REMEMBER_LOGIN_DATA_${organization}`;
};

export type LoginData = {
  password: string;
  email: string;
};

const getLoginDataFromStorage = async (organization: string) => {
  const data = await SecureStore.getItemAsync(getRememberLoginDataKey(organization));
  if (data) {
    const parsedData = JSON.parse(data);
    return parsedData as LoginData;
  }

  if (!extra?.isTerminalBuild) {
    // SecureStoreでデータが取れなかった場合、AsyncStorageでもトライ（SecureStoreでデータが消えている可能性があるため）
    const asyncData = await getItemAsync(getRememberLoginDataKey(organization));
    if (!asyncData) return;
    // AsyncStorageにはデータがあって、SecureStoreにはなかったので再保存する
    await SecureStore.setItemAsync(getRememberLoginDataKey(organization), asyncData);
    const parsedData = JSON.parse(asyncData);
    return parsedData as LoginData;
  }
};

const setLoginDataToStorage = async (params: { data: LoginData; organization: string }) => {
  const { data, organization } = params;
  const loginData = JSON.stringify({
    password: data.password,
    email: data.email,
  });
  await SecureStore.setItemAsync(getRememberLoginDataKey(organization), loginData);
  if (!extra?.isTerminalBuild) {
    await setItemAsync(getRememberLoginDataKey(organization), loginData); // SecureStoreが消える可能性があるので予備で保存
  }
};

const removeLoginDataFromStorage = async (organization: string) => {
  await Promise.all([
    SecureStore.deleteItemAsync(getRememberLoginDataKey(organization)),
    !extra?.isTerminalBuild && removeItemAsync(getRememberLoginDataKey(organization)),
  ]);
};

// 保存ID/PASSを自動入力
const getLoginFormInputCode = (data: LoginData) => {
  return `
setTimeout(() => {
  document.querySelector('[name="email"]').value = '${data.email}';
  document.querySelector('[name="password"]').value = '${data.password}'; 
}, 100);
true;
`;
};

export {
  getLoginDataFromStorage,
  setLoginDataToStorage,
  removeLoginDataFromStorage,
  getLoginFormInputCode,
};
