import * as Updates from 'expo-updates';

import { extra } from '../utils/extra';

const channel = Updates.channel;

const isProduction = (): boolean => {
  if (!channel) return false;

  // extraで設定した本番チャンネルと現在ビルドのchannelが一致するかチェック
  return extra?.productionChannel === channel;
};

export { isProduction };
